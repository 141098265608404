import { navigate } from '@reach/router';
import React, { FC, useEffect } from 'react';
import { track } from 'utils/analytics';

interface GettingStartedProps {}

const GettingStarted: FC<GettingStartedProps> = () => {
  useEffect(() => {
    track('Getting Started Brochure Viewed');
    navigate('/pdfs/getting-started.pdf');
  }, []);

  return <></>;
};
export default GettingStarted;
